import React from "react"
import { SEO } from "../components/Seo"
import { rhythm, scale } from "../utils/typography"
import { EtudesForTheWebBrowserHeader } from "../components/Header"
import { OpenInNewTab } from "../components/OpenInNewTab"
import { EmailSignup } from "../components/EmailSignup"
import { DiabeticRed } from "../utils/colors"
import theme from "../utils/theme"
import { useMediaQuery } from "react-responsive"
import { Footer } from "../components/Footer"
import { graphql } from "gatsby"
import Image from "gatsby-image"
// import { Link, graphql } from "gatsby"
// import PreviewImage from '../components/PreviewImage'
// import ColorHash from 'color-hash'

const BodyFontFamily = theme.bodyFontFamily
  .map((font, i) =>
    i !== theme.bodyFontFamily.length - 1 ? `'${font}'` : font
  )
  .join(", ")

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentType: { ne: "portfolio" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            description
            previewImageUrl {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            tags
          }
          fields {
            slug
          }
        }
      }
    }
    tangela: file(relativePath: { eq: "tangela.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mockup: file(relativePath: { eq: "mockup6.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    placeholder: file(relativePath: { eq: "600x400.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    codebase: file(relativePath: { eq: "codebase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    guideExample: file(relativePath: { eq: "guideexample.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    cardPileSystem: file(relativePath: { eq: "cardpilesystem2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gameCodebaseTour: file(relativePath: { eq: "gamecodebasetour.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    frontend: file(relativePath: { eq: "frontend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    chalkboard: file(relativePath: { eq: "chalkboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    leat: file(relativePath: { eq: "leat2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    pathways: file(relativePath: { eq: "pathways3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const GRAY_BACKGROUND = "rgba(243,247,249,1)"
const AVATAR_BACKGROUND = "#ddd"
// const HIGHLIGHT_BACKGROUND = "rgba(220, 224, 226, 1)"

const WebBrowser = ({ randomHeights }) => {
  const GUTTER = 4
  const BORDER_RADIUS = 12
  const NUM_RECTANGLES = randomHeights.length
  const [counter, setCounter] = React.useState(0)

  React.useEffect(() => {
    let timer

    function createTimeout(n) {
      timer = setTimeout(() => {
        setCounter(n)
        if (n < NUM_RECTANGLES) createTimeout(n + 1)
      }, 50)
    }

    timer = createTimeout(0)

    return () => {
      clearTimeout(timer)
    }
  }, [setCounter, NUM_RECTANGLES])

  console.log("Animation counter:", counter)

  return (
    <div
      style={{
        backgroundColor: "rgb(232, 232, 232)",
        borderRadius: BORDER_RADIUS,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 0,
        paddingBottom: 2,
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
      }}
    >
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          paddingBottom: GUTTER * 2,
          paddingTop: GUTTER * 2,
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            marginLeft: GUTTER * 3,
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: "rgb(237, 106, 94)",
              borderRadius: "50%",
              marginRight: GUTTER * 2,
            }}
          />
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: "#f5be4f",
              borderRadius: "50%",
              marginRight: GUTTER * 2,
            }}
          />
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: "#61c455",
              borderRadius: "50%",
              marginRight: GUTTER * 2,
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: "white",
            flexGrow: 1,
            height: 24,
            borderRadius: GUTTER,
            maxWidth: "40%",
            width: "40%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderBottomLeftRadius: BORDER_RADIUS,
          borderBottomRightRadius: BORDER_RADIUS,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "3vh",
            backgroundColor: GRAY_BACKGROUND,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "40vh",
          }}
        >
          <div
            style={{
              width: "12%",
              marginRight: GUTTER,
            }}
          >
            <div
              style={{
                backgroundColor: GRAY_BACKGROUND,
                height: "30%",
                marginBottom: GUTTER,
                marginTop: GUTTER,
                display: "flex",
                justifyContent: "center",
                paddingTop: GUTTER * 2,
              }}
            ></div>
            <div style={{ backgroundColor: GRAY_BACKGROUND, height: "15%" }} />
          </div>
          <div
            style={{
              width: "30%",
              marginRight: GUTTER,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: GRAY_BACKGROUND,
                height: `25%`,
                marginBottom: GUTTER,
                marginTop: GUTTER,
              }}
              className="web-browser-box"
            />
            {Array(5 + 1)
              .join(".")
              .split("")
              .map((_, i) => (
                <div
                  key={i}
                  style={{
                    backgroundColor: GRAY_BACKGROUND,
                    height: `${15 + randomHeights[i]}%`,
                    marginBottom: GUTTER,
                    marginTop: GUTTER,
                  }}
                  className={counter > i ? "web-browser-box" : ""}
                />
              ))}
          </div>
          <div style={{ width: "12%", marginTop: GUTTER }}>
            <div
              style={{
                backgroundColor: GRAY_BACKGROUND,
                height: "30%",
                marginBottom: GUTTER,
              }}
            />
            <div style={{ backgroundColor: GRAY_BACKGROUND, height: "10%" }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Page({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const Heading = props => {
    if (!props.is) {
      throw new Error('"is" property is required.')
    }

    const style = {
      marginTop: 0,
      paddingTop: rhythm(3),
      textAlign: props.textAlign || "center",
      fontFamily: BodyFontFamily,
      ...(isMobile ? { ...scale(1 / 2), lineHeight: rhythm(4 / 3) } : {}),
    }

    if (props.is === "h1") {
      return <h1 style={style}>{props.children}</h1>
    }

    if (props.is === "h2") {
      return <h2 style={style}>{props.children}</h2>
    }

    if (props.is === "h3") {
      return <h3 style={style}>{props.children}</h3>
    }

    if (props.is === "h4") {
      return <h4 style={style}>{props.children}</h4>
    }
  }

  const Section = props => {
    return (
      <section
        style={{
          maxWidth: 600,
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: rhythm(1 / 2),
          paddingRight: rhythm(1 / 2),
        }}
      >
        {props.children}
      </section>
    )
  }

  const HighlightedBackground = props => (
    <div style={{ backgroundColor: GRAY_BACKGROUND }}>{props.children}</div>
  )

  const Subheader = props => {
    return (
      <h2
        style={{
          textAlign: "center",
          fontFamily: BodyFontFamily,
          fontWeight: 100,
          marginTop: 0,
          ...(isMobile ? scale(1 / 3) : {}),
          ...(props.style || {}),
        }}
      >
        {props.children}
      </h2>
    )
  }

  return (
    <>
      <SEO
        description="Become career-ready in React, with exercises designed by a senior frontend dev."
        imageUrl="https://user-images.githubusercontent.com/914228/175034958-e4a528af-825a-45d3-9807-a811848574a1.png"
        imageAlt="Become career-ready in React, with exercises designed by a senior frontend dev."
        title="Etudes for the Web Browser"
      />
      <EtudesForTheWebBrowserHeader
        styleProps={{ backgroundColor: GRAY_BACKGROUND }}
        avatarStyleProps={{ backgroundColor: AVATAR_BACKGROUND }}
      />
      <main>
        <HighlightedBackground>
          <Section>
            <Heading is="h1" textAlign="left">
              Become <span style={{ color: DiabeticRed }}>career-ready</span> in
              React,
              <br />
              with exercises designed by a senior frontend dev.
            </Heading>
            <Subheader style={{ textAlign: "left" }}>
              <em>Etudes for the Web Browser</em> is a workbook that hones your
              frontend skills to a professional level with progressive coding
              projects.
            </Subheader>
          </Section>
          <div
            style={{
              maxWidth: 750,
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: rhythm(1 / 2),
              paddingRight: rhythm(1 / 2),
              marginBottom: rhythm(2),
              marginTop: rhythm(3 / 2),
            }}
          >
            <WebBrowser
              randomHeights={Array(10)
                .join(".")
                .split("")
                .map(_ => Math.random() * 20)}
            />
          </div>
          {/*
          <div
            style={{
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: rhythm(1 / 2),
              paddingRight: rhythm(1 / 2),
              marginBottom: rhythm(1),
            }}
          >
            <Image fluid={data.mockup.childImageSharp.fluid} alt="mockup" />
          </div>
          <Section>
            <iframe
              title="call to action 1"
              frameBorder="0"
              src="https://itch.io/embed/1111372"
              width="100%"
              height="167"
            >
              <a href="https://nucleartide.itch.io/pocket-pong-ar">
                Pocket Pong AR by nucleartide
              </a>
            </iframe>
          </Section>
          */}
          <Section>
            <EmailSignup
              altColor
              urlPath="/etudes-for-the-web-browser"
              copy={
                <>
                  <span>
                    Sign up to be notified when{" "}
                    <em>Etudes for the Web Browser</em> is launched:
                  </span>
                </>
              }
              style={{
                paddingTop: 0,
                paddingBottom: rhythm(1),
              }}
            />
            <Subheader
              style={{
                marginBottom: 0,
                paddingBottom: rhythm(1),
                fontStyle: "italic",
              }}
            >
              Or take a look below ↓
            </Subheader>
          </Section>
        </HighlightedBackground>
        <Section>
          <Heading is="h1">
            <span>So you want to learn frontend development... </span>
            <span role="img" aria-label="light bulb">
              🖼️
            </span>
          </Heading>
          <p>
            Maybe you want to get your first job as a junior frontend developer.
          </p>
          <p>
            Or maybe you have experience under your belt, but you want to dip
            your toes into the frontend stack at work.
          </p>
          <p>Either way, you run into one of 3 issues:</p>
          <ol>
            <li>
              <strong>It's so overwhelming.</strong> You're unsure what to
              learn, what the roadmap looks like, or what might be the perfect
              course for you.
            </li>
            <li>
              <strong>Tutorial hell.</strong> You might watch hours and hours of
              videos, but still don't feel like you learned anything at the end.{" "}
              <em>You still can't do the job, after all.</em>
            </li>
            <li>
              <strong>Hopelessness.</strong> You don't know if you'll ever land
              that job, or whether your experience is the norm, or whether
              you're taking too long, or whether you're working on the right
              projects.
            </li>
          </ol>
          <p>
            The result? <strong>You give up.</strong>
          </p>
          <p>
            You think that frontend work isn't right for you, or that you like
            backend work more.
          </p>
          <p>
            And you miss out on a career path that is not only lucrative – good
            frontend engineers are <em>very</em> well-compensated – but also
            tremendously satisfying for people who love working on user-facing
            products.
          </p>
          <div>
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Image
                fluid={data.frontend.childImageSharp.fluid}
                alt="frontend code"
              />
            </div>
            <p className="mario-caption">
              <small>
                Build UIs that get used in every part of today's knowledge
                economy. Frontend is a blast.
              </small>
            </p>
          </div>
        </Section>
        <Section>
          <Heading is="h2">
            But there's always hope...{" "}
            <span role="img" aria-label="light bulb">
              💡
            </span>
          </Heading>
          <p>Overwhelm and tutorial hell are solvable problems.</p>
          <p>
            And if you're looking for a job, all you need is a plan from people
            who've been in your shoes, and succeeded.
          </p>
          <p>
            <em>You can do this</em>, if only:
          </p>
          <ol>
            <li>You knew exactly what you needed to learn.</li>
            <li>
              You completely understood the lesson behind any tutorial, and feel
              like you have a firm grasp of the concepts.{" "}
              <strong>Tutorial heaven</strong>, not tutorial hell.
            </li>
            <li>
              You have utmost confidence that you'll get that job, or knock out
              that frontend task at work. And you feel <em>empowered</em> to put
              in the work to get there.
            </li>
          </ol>
          <p>But... how?</p>
        </Section>
        <Section>
          <Heading is="h1">
            Become career-ready in React, with{" "}
            <em>Etudes for the Web Browser</em> 🎶
          </Heading>
        </Section>
        <div
          style={{
            maxWidth: 750,
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: rhythm(1 / 2),
            paddingRight: rhythm(1 / 2),
            marginBottom: rhythm(1),
          }}
        >
          <WebBrowser
            randomHeights={Array(10)
              .join(".")
              .split("")
              .map(_ => Math.random() * 20)}
          />
        </div>
        <Section>
          <p>You need a roadmap. A curriculum.</p>
          <p>
            <OpenInNewTab href="https://roadmap.sh/frontend">
              Not one that is more overwhelming, mind you
            </OpenInNewTab>
            . But rather, one that teaches you <em>exactly</em> what you need to
            know, so that you can let experience take you the rest of the way.
          </p>
          <p>
            Thus, I introduce you to <strong>Etudes for the Web Browser</strong>
            : a comprehensive workbook of frontend exercises based on my decade
            of full-stack (and frontend-heavy) software engineering experience.
          </p>
          <p>
            If you successfully complete all the exercises in this workbook, you
            will be fit to do frontend development at 95% of companies out
            there. Heck, I'd even hire you personally.
          </p>
          <p>Intrigued? Take a look at the book's contents below...</p>
        </Section>
        <Section>
          <Heading is="h2" textAlign="left">
            1. Tackle overwhelm with a no-fluff curriculum designed by a senior
            engineer.{" "}
            <span role="img" aria-label="boxing glove">
              🥊
            </span>
          </Heading>
          <div style={{ marginBottom: rhythm(1) }}>
            <Image
              fluid={data.chalkboard.childImageSharp.fluid}
              alt="chalkboard"
            />
          </div>
          <p>
            <em>Etudes for the Web Browser</em> teaches a curriculum that
            contains no fluff, with a heavy emphasis on exercises designed by a
            working senior engineer.
          </p>
          <p>
            Although it's in the early stages, here's a rough pass at what the
            curriculum will cover:
          </p>
          <blockquote>
            <ol>
              <em>
                <strong>Chapters</strong>
              </em>
              <br />
              <br />
              <li>
                <strong>Applying for jobs</strong>, and the power of focusing on
                one application
              </li>
              <li>
                <strong>How to build your skills</strong>, with the LEAT
                framework
              </li>
              <li>
                <strong>HTML</strong>, the important concepts to practice
              </li>
              <li>
                <strong>CSS</strong>, the important concepts to practice
              </li>
              <li>
                <strong>JavaScript</strong>, the important concepts to practice
              </li>
              <li>
                <strong>The DOM</strong>, manipulation and scripting
              </li>
              <li>
                <strong>React</strong>, the important concepts to practice
              </li>
              <li>
                <strong>Common UI patterns</strong> to practice building
              </li>
              <li>
                <strong>Portfolio project</strong>
              </li>
            </ol>
          </blockquote>
          <p>
            But how is this better than a plain ol' book or video course? That
            leads us to...
          </p>
        </Section>
        <Section>
          <Heading is="h2" textAlign="left">
            2. Climb out of tutorial hell with the LEAT framework.{" "}
            <span role="img" aria-label="scope">
              🧗
            </span>
          </Heading>
          <p>
            LEAT is a mental framework for learning through <em>action</em>. It
            stands for:
          </p>
          <div style={{ marginBottom: rhythm(1) }}>
            <Image
              fluid={data.leat.childImageSharp.fluid}
              alt="Learn, Exercise, Apply, Teach"
            />
          </div>
          <p>
            Thus, every module in <em>Etudes for the Web Browser</em> is
            structured with the LEAT approach in mind:
          </p>
          <ol>
            <li>
              <strong>Learn</strong>, by doing some googling and documentation
              reading.
            </li>
            <li>
              <strong>Exercise,</strong> by doing the module's problem set
              (which will require writing code of your own). Think of it as a
              math workbook of sorts.
            </li>
            <li>
              <strong>Apply</strong>, by choosing a <em>portfolio project</em>,
              and thinking of a feature that makes use of your newly learned
              concepts.
            </li>
            <li>
              <strong>Teach</strong>, by writing a summary of how your portfolio
              project applies the skills learned in each of{" "}
              <em>Etudes for the Web Browser's</em> modules.
            </li>
          </ol>
          <p>The benefit?</p>
          <p>
            Unlike other educational materials that have you wade through hours
            of video content, <em>Etudes</em> has you solving real frontend
            problems from day 1.
          </p>
          <p>
            <details>
              <summary>
                <span role="img" aria-label="see below" class="icon">👇</span>{" "}
                <strong>
                  Here are some examples (click or press this sentence to
                  expand):
                </strong>{" "}
                <span role="img" aria-label="see below" class="icon">👇</span>
              </summary>
              <ol>
                <li>
                  Build a fuzzy search UI component to make the{" "}
                  <OpenInNewTab href="https://www.google.com/search?q=ask+hn+who%27s+hiring+site:news.ycombinator.com">
                    Ask HN: Who’s hiring?
                  </OpenInNewTab>{" "}
                  thread easier to sift.
                </li>
                <li>Build a UI component for gathering user feedback.</li>
                <li>
                  Grab all the DOM nodes containing user names on this web page.
                </li>
                <li>
                  Build a diagram maker that allows me to create boxes, arrows,
                  and type text.
                </li>
                <li>
                  Build a UI that makes it easier for me to narrow down my hobby
                  selection from{" "}
                  <OpenInNewTab href="https://en.wikipedia.org/wiki/List_of_hobbies">
                    Wikipedia's list of hobbies
                  </OpenInNewTab>
                  .
                </li>
                <li>Given this React code, make the design responsive.</li>
              </ol>
            </details>
          </p>
          <p>
            And the best part: upon finishing the workbook, you will have a{" "}
            <em>portfolio of mini-projects</em> to demonstrate your newfound
            skills to any potential employers or bosses.
          </p>
          <p>
            But if you're in the middle of a career transition, or are otherwise
            feeling a little hopeless, perhaps...
          </p>
        </Section>
        <Section>
          <Heading is="h2" textAlign="left">
            3. Feel empowered and confident with a step-by-step approach.{" "}
            <span role="img" aria-label="maintainable">
              🏋️
            </span>
          </Heading>
          <p>Whether you are:</p>
          <ol>
            <li>looking for your first job,</li>
            <li>getting into frontend as an experienced developer, or</li>
            <li>
              just looking to learn some light DOM scripting as a product
              manager,
            </li>
          </ol>
          <p>
            different pathways through the book will guide you to your desired
            learning outcome:
          </p>
          <div style={{ marginBottom: rhythm(1) }}>
            <Image
              fluid={data.pathways.childImageSharp.fluid}
              alt="learning pathways"
            />
          </div>
          <p>
            Say goodbye to hopelessness. With the book's focus on{" "}
            <strong>action</strong>, you can feel empowered – knowing that the
            only thing keeping you from your goals is{" "}
            <em>putting in the work</em>.
          </p>
          <div style={{ paddingBottom: rhythm(2) }} />
        </Section>
        <HighlightedBackground>
          <Section>
            <Heading is="h1">
              Interested?{" "}
              <span role="img" aria-label="eyes">
                👀
              </span>
            </Heading>
            <EmailSignup
              altColor
              urlPath="/etudes-for-the-web-browser"
              copy={
                <>
                  <span>
                    <em>Etudes for the Web Browser</em> is a work-in-progress,
                    and hasn't been released yet.
                  </span>
                  <br />
                  <br />
                  <span>
                    If you're interested, please sign up for email updates as
                    the workbook makes its way to launch:
                  </span>
                  <br />
                  <br />
                </>
              }
            />
            <div style={{ paddingBottom: rhythm(1) }} />
          </Section>
        </HighlightedBackground>
        <Section>
          <Heading is="h1">
            Frequently Asked Questions{" "}
            <span role="img" aria-label="thinking">
              🤔
            </span>
          </Heading>
          <Heading is="h3" textAlign="left">
            Who are you anyway, and why should I listen to you?
          </Heading>
          <p>
            <p>
              <em>Etudes for the Web Browser</em> is made by me,{" "}
              <OpenInNewTab href="https://www.linkedin.com/in/jasontu/">
                Jason Tu
              </OpenInNewTab>
              .
            </p>
            <p>
              I've been developing frontend JavaScript applications since the
              jQuery and Backbone.js days (2013), seen the evolution of MVC
              frameworks such as Ember.js, and have continued working all the
              way to today's modern React and TypeScript stacks.
            </p>
            <p>
              And I have significant professional experience, having worked as a
              full-stack engineer across many businesses (Wonder, Segment.io,
              Jet.com, Zynga, to name a few). I've mentored several people at
              these companies, and bootstrapped them with just enough frontend
              skills to be dangerous.
            </p>
            <p>
              In short: I spent my entire 20's doing this (I'm 29 now!). I know
              my stuff, and I can teach you what I know.
            </p>
          </p>
          <Heading is="h3" textAlign="left">
            How is this book better than The Odin Project, Scrimba, Udemy, or
            any of the other course platforms out there?
          </Heading>
          <p>
            Many of the courses out there are fantastic. If those courses work
            for you, then great!
          </p>
          <p>
            However, <em>Etudes for the Web Browser</em> was born out of
            frustration. There’s little training material for developers who
            want to contribute to frontend codebases <strong>right away</strong>
            , or want nothing more than to land their first job, but lack a
            proper understanding of the fundamentals.
          </p>
          <p>
            In such a situation, you really need something practical – something
            that gets your hands dirty as soon as possible, taught by someone
            with hard-won industry experience.
          </p>
          <p>That’s what I hope to provide as part of my book</p>
          <Heading is="h3" textAlign="left">
            Will this book be free or paid?
          </Heading>
          <p>
            This book will be a paid product. That said, I’m not sure what the
            pricing will look like yet – I still have to flesh out the
            workbook’s contents!
          </p>
          <Heading is="h3" textAlign="left">
            What prerequisites should I know before diving into this workbook?
          </Heading>
          <p>You should have a general familiarity of:</p>
          <ul>
            <li>how to use a web browser</li>
            <li>
              how to use a plain text editor (versus a rich text editor, such as
              Microsoft Word)
            </li>
            <li>
              the basics of HTTP, web servers, and how browsers work (see my{" "}
              <OpenInNewTab href="https://jasont.co/frontend/">
                frontend overview blog post
              </OpenInNewTab>{" "}
              for a good rundown)
            </li>
          </ul>
          <p>
            Assuming you are familiar with the basic concepts of web
            development, this book will take you further into practical skill
            territory.
          </p>
          <Heading is="h3" textAlign="left">
            How long will it take to finish the workbook?
          </Heading>
          <p>
            This remains to be seen! Once I am finished with the workbook and
            have tested it with some initial readers, I’ll be able to give a
            less hand-wavy time estimate.
          </p>
          <p>
            That said, the workbook is intended to ramp you up on frontend
            skills as fast as possible. Depending on your goals, you can find
            immediate value from the skills you learn in early modules – without
            completing the entire book.
          </p>
          <Heading is="h3" textAlign="left">
            What's included in the workbook?
          </Heading>
          <p>For now, my plan is as follows:</p>
          <ol>
            <li>Produce a workbook.</li>
            <li>
              Produce a set of exercises for each chapter, as well as
              corresponding answers (which will inlude source code).
            </li>
          </ol>
          <p>
            I also plan on creating a meeting format for a 1:1 office hours
            meeting, where we can meet regularly to discuss progress towards
            goals.
          </p>
          <Heading is="h3" textAlign="left">
            What if I go through the workbook, and still have questions?
          </Heading>
          <p>
            For now, you can shoot me an email at{" "}
            <code>jasontu4@gmail.com</code> and I’ll get back to you shortly!
          </p>
          <p>
            But these plans are tentative, and I’d like to produce the workbook
            first before deciding on support plans. (There could also be a
            Discord channel, or office hours, or a GitHub issue tracker for
            example.)
          </p>
        </Section>
      </main>
      <div style={{ marginTop: rhythm(3) }}>
        <Footer />
      </div>
    </>
  )
}
